import * as React from 'react';

import {
    Breakpoints,
    Container,
    ContainerWrap,
    css,
    styled
} from '@volkswagen-onehub/components-core';
import {getContainerPadding, getEndDirection} from '../helpers';

const lineWidth = '2px';
const symbolLineWidthMobile = '4px';
const symbolLineWidthDesktop = '6px';
const symbolCapSize = '10px';

export interface ProsConsItemProps {
    readonly appearance?: 'negative' | 'positive';
    readonly isFirstChild?: boolean;
    readonly isLastChild?: boolean;
    readonly mediaElement?: JSX.Element;
}

const StyledSymbol = styled.div`
    position: relative;
    flex-shrink: 0;

    &::before,
    &::after {
        content: '';
        position: absolute;
        background: ${props => props.theme.colors.signal.progress};
    }

    &::after {
        top: 50%;
        height: ${symbolLineWidthMobile};
        width: 100%;
        transform: translateY(-50%);

        @media (min-width: ${Breakpoints.b560}px) {
            height: ${symbolLineWidthDesktop};
        }
    }
`;
StyledSymbol.displayName = 'StyledSymbol';

export const StyledSymbolContainer = styled.div`
	@media (min-width: ${Breakpoints.b560}px) {
		z-index: 1;
		position: absolute;
		${props => getEndDirection(props.theme.direction)}: calc(100% + ${props =>
    props.theme.size.grid001});
		width: ${props => props.theme.size.grid001};
		padding: ${props => props.theme.size.dynamic0050} 0;
		background-color: ${props => props.theme.colors.background.primary};
	}
`;
StyledSymbolContainer.displayName = 'StyledSymbolContainer';

export const StyledPlusSymbol = styled(StyledSymbol)`
    height: ${props => props.theme.size.grid002};
    margin: ${props => props.theme.size.dynamic0050} 0;

    &::before {
        left: 50%;
        width: ${symbolLineWidthMobile};
        height: 100%;
        transform: translateX(-50%);

        @media (min-width: ${Breakpoints.b560}px) {
            width: ${symbolLineWidthDesktop};
        }
    }

    @media (min-width: ${Breakpoints.b560}px) {
        margin: 0;
        height: ${props => props.theme.size.grid001};
    }
`;
StyledPlusSymbol.displayName = 'StyledPlusSymbol';

export const StyledMinusSymbol = styled(StyledSymbol)`
    @media (max-width: ${Breakpoints.b560}px) {
        padding: ${props => props.theme.size.dynamic0050} 0
            ${props => props.theme.size.dynamic0100};
    }
`;
StyledMinusSymbol.displayName = 'StyledMinusSymbol';

const StyledLine = styled.div`
    width: ${lineWidth};
    min-height: ${props => props.theme.size.dynamic0270};
    margin: auto;
    background: ${props => props.theme.colors.signal.progress};

    @media (min-width: ${Breakpoints.b560}px) {
        z-index: -1;
        height: 100%;
    }
`;
StyledLine.displayName = 'StyledLine';

const StyledIconWrapper = styled.div<ProsConsItemProps>`
    ${props =>
        props.appearance === 'negative' || props.appearance === 'positive'
            ? 'display: block;'
            : 'display: none;'}
    width: ${props => props.theme.size.grid002};
    flex-shrink: 0;
    margin: ${props => props.theme.size.dynamic0100} ${props =>
    props.theme.size.grid002} 0;

    @media (min-width: ${Breakpoints.b560}px) {
        margin: 0 ${props => props.theme.size.grid002};
				text-align: center;
    }

    ${props => {
        if (props.isFirstChild) {
            return css`
                ::before {
                    content: '';
                    display: block;
                    width: ${symbolCapSize};
                    height: ${symbolCapSize};
                    margin: 0 auto;
                    border-radius: 50%;
                    background: ${props.theme.colors.signal.progress};
                }
            `;
        }

        return;
    }}

    @media (min-width: ${Breakpoints.b560}px) {
        position: relative;
        z-index: 0;
        display: block;
        width: ${props => props.theme.size.grid001};
        margin: 0;

        ${props =>
            props.isFirstChild &&
            `margin-top: ${props.theme.size.dynamic0100};`}

        ${props => {
            if (props.isLastChild) {
                return css`
                    ::after {
                        content: '';
                        display: block;
                        width: ${symbolCapSize};
                        height: ${symbolCapSize};
                        margin: auto;
                        transform: translateY(-100%);
                        background: ${props.theme.colors.signal.progress};
                    }
                `;
            }

            return;
        }}
    }
`;
StyledIconWrapper.displayName = 'StyledIconWrapper';

export const StyledMobileWrapper = styled.div`
    display: block;

    @media (min-width: ${Breakpoints.b560}px) {
        display: none;
    }
`;
StyledMobileWrapper.displayName = 'StyledMobileWrapper';

const StyledProsConsItem = styled.div`
    @media (min-width: ${Breakpoints.b560}px) {
        display: flex;
        max-width: ${props => props.theme.size.grid015};
        margin: 0 ${props => props.theme.size.grid002};
    }
`;
StyledProsConsItem.displayName = 'StyledProsConsItem';

const StyledContentWrapper = styled.div<ProsConsItemProps>`
    width: 100%;
    ${props =>
        !props.isLastChild &&
        css`
            @media (min-width: ${Breakpoints.b560}px) {
                margin-bottom: ${props.theme.size.dynamic0270};
            }
        `}
`;
StyledContentWrapper.displayName = 'StyledContentWrapper';

const CONTAINER_PADDING_2_1 = getContainerPadding([2, 1]);

export const ProsConsItem: React.FunctionComponent<ProsConsItemProps> = props => (
    <StyledProsConsItem>
        <StyledIconWrapper
            isFirstChild={props.isFirstChild}
            isLastChild={props.isLastChild}
            appearance={props.appearance}
        >
            <StyledLine />
            <StyledMobileWrapper>
                <StyledSymbolContainer>
                    {props.appearance === 'negative' && <StyledMinusSymbol />}
                    {props.appearance === 'positive' && <StyledPlusSymbol />}
                </StyledSymbolContainer>
            </StyledMobileWrapper>
        </StyledIconWrapper>
        <StyledContentWrapper isLastChild={props.isLastChild}>
            <Container
                wrap={ContainerWrap.always}
                padding={{
                    right: CONTAINER_PADDING_2_1,
                    left: CONTAINER_PADDING_2_1
                }}
            >
                {props.children}
            </Container>
        </StyledContentWrapper>
        <StyledMobileWrapper>{props.mediaElement}</StyledMobileWrapper>
    </StyledProsConsItem>
);
