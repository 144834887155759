import * as React from 'react';
import {ThemeContext} from 'styled-components';

import {
    Breakpoints,
    Container,
    ContainerGutter,
    ContainerWrap,
    CTA,
    styled,
    Text,
    TokenTextAppearance
} from '@volkswagen-onehub/components-core';
import {ChevronLeft, ChevronRight} from '@volkswagen-onehub/icons-core';
import {
    Direction,
    getContainerPadding,
    getStartDirection
} from '../../../d6/components/helpers';
import {
    CarFeatureCategoryItemModel,
    FeatureClusterSectionModel
} from '../../../../generated/core';
import {AuthoringWrapper} from '../../../components/AuthoringWrapper';
import {Richtext} from '../../../components/Richtext';
import {SectionDisclaimerContainer} from '../../../components/disclaimers/SectionDisclaimerContainer';

import {
    useBBOContext,
    usePersonalizationContext,
    usePersonalizationStore,
    useSectionGroupContext,
    useTrackingService
} from '../../../context';
import {ContainerItem} from '../../../infrastructure/ContainerExporter';
import {MapTo} from '../../../infrastructure/compatibility/MapTo';
import {C} from '../../../registries/compatibilty';
import {getChildItems} from '../../../utils/container/getContainerChildItems';
import {createContextTrackingData} from '../../../utils/tracking/createContextTrackingData';
import {Parsys, ParsysType} from '../../structure/Parsys';
import {HeadingElement} from '../elements/HeadingElement';
import {CarFeatureCategoryItem} from '../items/CarFeatureCategoryItem';
import {CopyItem} from '../items/CopyItem';
import {SectionWrapper} from './SectionWrapper';
import {
    FeatureCluster,
    FeatureClusterListItem,
    FeaturesCategory
} from '../../../d6/components/feature-cluster';
import {GeneralDisclaimerProvider} from '../../../context/disclaimer/GeneralDisclaimerProvider';

const PARSYS_PATH = 'parsys';
const CONTAINER_PADDING_2_3_5 = getContainerPadding([2, 3, 5]);
const CONTAINER_PADDING_2_1 = getContainerPadding([2, 1]);

const StyledCopyWrapper = styled.div`
    padding-${props => getStartDirection(props.theme.direction)}: ${props =>
    props.theme.size.grid001};
    padding-inline-start: ${props => props.theme.size.grid001};

	@media (min-width: ${Breakpoints.b960}px) {
		padding-${props => getStartDirection(props.theme.direction)}: ${props =>
    props.theme.size.grid002};
		padding-inline-start: ${props => props.theme.size.grid002};
	}

	@media (min-width: ${Breakpoints.b1280}px) {
		padding-${props => getStartDirection(props.theme.direction)}: ${props =>
    props.theme.size.grid001};
		padding-inline-start: ${props => props.theme.size.grid001};
	}
`;

function AuthorView(props: FeatureClusterSectionModel): JSX.Element {
    const {anchorId, tagTypeBroken} = props;

    return (
        <AuthoringWrapper
            anchorId={anchorId}
            title="S127 Feature Cluster Section"
            bgColor={AuthoringWrapper.BG_COLOR_SECTION}
            tagTypeBroken={tagTypeBroken}
        >
            <Container
                stretchContent
                wrap={ContainerWrap.always}
                padding={{
                    inlineStart: CONTAINER_PADDING_2_3_5,
                    inlineEnd: CONTAINER_PADDING_2_3_5
                }}
                gutter={ContainerGutter.dynamic0100}
            >
                <HeadingElement path="heading" style="H0" order="H2" />
                <Container padding={{inlineStart: CONTAINER_PADDING_2_1}}>
                    <CopyItem path="copy" useDropCap={false} />
                </Container>
                <Parsys
                    path={PARSYS_PATH}
                    parsysType={ParsysType.ITEM_PARSYS}
                />
            </Container>
        </AuthoringWrapper>
    );
}

function getCategory(
    item: ContainerItem,
    btnBackToOverviewLabel: string,
    onListItemClick: () => void,
    onBackClick: () => void
): FeaturesCategory {
    const categoryProps = item.model as CarFeatureCategoryItemModel;

    const chevron = (
        <ThemeContext.Consumer>
            {({direction}) =>
                direction === Direction.RTL ? (
                    <ChevronRight variant="small" ariaHidden />
                ) : (
                    <ChevronLeft variant="small" ariaHidden />
                )
            }
        </ThemeContext.Consumer>
    );

    return {
        label: (
            <FeatureClusterListItem
                key={categoryProps.title}
                onClick={onListItemClick}
            >
                {categoryProps.rtTitle ? (
                    <Richtext
                        richtext={categoryProps.rtTitle}
                        appearance={TokenTextAppearance.headline400}
                        inheritColor
                    />
                ) : (
                    <Text appearance={TokenTextAppearance.headline400}>
                        {categoryProps.title}
                    </Text>
                )}
            </FeatureClusterListItem>
        ),
        detail: (
            <Container
                wrap={ContainerWrap.always}
                gutter={ContainerGutter.static400}
            >
                <Text appearance={TokenTextAppearance.label200} bold>
                    <CTA tag="button" emphasis="tertiary" onClick={onBackClick}>
                        {chevron} {btnBackToOverviewLabel}
                    </CTA>
                </Text>
                <CarFeatureCategoryItem path={`${PARSYS_PATH}/${item.key}`} />
            </Container>
        )
    };
}

const useCategoryItems = (
    itemsData: ContainerItem[],
    setActiveItem: (idx: number | null) => void,
    trackCategoryClick: (isOpen: boolean, item: ContainerItem) => void,
    btnBackToOverviewLabel: string
) =>
    itemsData.map((item, idx) => {
        function onListItemClick(): void {
            setActiveItem(idx);
            trackCategoryClick(true, item);
        }

        function onBackClick(): void {
            setActiveItem(null);
            trackCategoryClick(false, item);
        }

        return getCategory(
            item,
            btnBackToOverviewLabel,
            onListItemClick,
            onBackClick
        );
    });

function PublishView(props: FeatureClusterSectionModel): JSX.Element {
    const [activeItem, setActiveItem] = React.useState<number | null>(null);
    const trackingService = useTrackingService();
    const personalizationContext = usePersonalizationContext();
    const sectionGroupContext = useSectionGroupContext();
    const bboContex = useBBOContext();
    const ps = usePersonalizationStore();

    const {
        anchorId,
        mboxId,
        contentName,
        contentLabels,
        categoryOverviewLabel,
        cqItems,
        btnBackToOverviewLabel,
        contentId: sectionId
    } = props;

    const caption = (
        <Text appearance={TokenTextAppearance.headline100} bold>
            {categoryOverviewLabel}
        </Text>
    );

    function trackCategoryClick(isOpen: boolean, item: ContainerItem): void {
        const linkName = item.model.title;
        trackingService.trackAccordionClick(
            sectionId,
            isOpen,
            window.location.pathname,
            linkName,
            createContextTrackingData(
                personalizationContext,
                ps.getMetaData(),
                bboContex,
                {sectionId},
                sectionGroupContext.sectionGroupContentId,
                contentLabels
            )
        );
    }

    const itemsData: ContainerItem[] = cqItems.parsys
        ? getChildItems(cqItems.parsys)
        : [];

    const categoryItems = useCategoryItems(
        itemsData,
        setActiveItem,
        trackCategoryClick,
        btnBackToOverviewLabel
    );

    return (
        <SectionWrapper
            mboxId={mboxId}
            anchorId={anchorId}
            sectionId={sectionId}
            contentLabels={contentLabels}
            contentName={contentName}
        >
            <GeneralDisclaimerProvider displayTypes={['T3_SECTION_BASED']}>
                <FeatureCluster
                    activeItem={activeItem}
                    caption={caption}
                    items={categoryItems}
                >
                    <HeadingElement path="heading" style="H0" order="H2" />
                    <StyledCopyWrapper>
                        <CopyItem
                            appearance={TokenTextAppearance.bigcopy100}
                            path="copy"
                        />
                    </StyledCopyWrapper>
                </FeatureCluster>
                <SectionDisclaimerContainer
                    displayTypes={['T3_SECTION_BASED']}
                />
            </GeneralDisclaimerProvider>
        </SectionWrapper>
    );
}

function InternalFeatureClusterSection(
    props: FeatureClusterSectionModel
): JSX.Element {
    return C.isInEditor() ? (
        <AuthorView {...props} />
    ) : (
        <PublishView {...props} />
    );
}

export const FeatureClusterSection = MapTo(
    'vwa-ngw18/components/editorial/sections/featureClusterSection',
    InternalFeatureClusterSection
);
