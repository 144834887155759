// @ts-ignore
import * as React from 'react';
import ReactDOM from 'react-dom';
import styled, {ThemeContext, withTheme} from 'styled-components';

import {
    Breakpoints,
    Container,
    ContainerGutter,
    ContainerHorizontalAlignment,
    ContainerPadding,
    ContainerWrap,
    Layout,
    ThemeDefinition,
    getColumnsWidthForBreakpoint,
    getGridColumn
} from '@volkswagen-onehub/components-core';
import {
    Direction,
    getElectricTransition,
    getEndDirection,
    getStartDirection
} from '../helpers';
import {Fade} from '../../../utils/Fade';

export * from './feature-cluster-list-item';

const animationDuration = 300;
const animationDistance = getGridColumn(1);
const animationEasing = 'cubic-bezier(0.14, 1.12, 0.67, 0.99)';

export interface FeaturesCategory {
    readonly label: React.ReactElement;
    readonly detail: React.ReactElement;
}

interface InternalFeatureClusterProps {
    readonly activeItem: number | null;
    readonly caption?: React.ReactElement;
    readonly items: FeaturesCategory[];
    readonly theme: ThemeDefinition;
}

export type FeatureClusterProps = React.PropsWithChildren<
    InternalFeatureClusterProps
>;

// Padding top has to be set, otherwise caption is cropped
const StyledAnimationContainer = styled.div`
	overflow: hidden;
	width: 100%;

	margin-top: ${props => props.theme.size.dynamic0250};
	padding-top: ${props => props.theme.size.static200};

	@media (min-width: ${Breakpoints.b960}px) {
		padding-${props => getStartDirection(props.theme.direction)}: ${props =>
    props.theme.size.grid004};
	}

	@media (min-width: ${Breakpoints.b1280}px) {
		margin-top: 0;
		padding-${props => getStartDirection(props.theme.direction)}: 0;
		padding-${props => getEndDirection(props.theme.direction)}: ${props =>
    props.theme.size.grid002};
	}
`;

interface StyledProps {
    readonly isExpanded?: boolean;
}

interface StyledFeaturesWrapperProps extends StyledProps {
    readonly minHeight: number | null;
}

const StyledFeaturesWrapper = styled.div<StyledFeaturesWrapperProps>`
	position: relative;
	border: 2px solid ${props => props.theme.colors.content.primary};
	padding: ${props => props.theme.size.dynamic0270}
		${props => props.theme.size.grid002};
	width: 100%;
	transform: translateX(${getGridColumn(1)});
	${getElectricTransition('transform')};

	min-height: ${props =>
        props.minHeight !== null ? `${props.minHeight}px` : 'auto'};

	${props =>
        props.isExpanded &&
        `
			transform: translateX(-${getGridColumn(1)});
			padding-${getStartDirection(props.theme.direction)}: ${
            props.theme.size.grid003
        };
	`}

	@media (min-width: ${Breakpoints.b960}px) {
		width: ${getColumnsWidthForBreakpoint(17, Breakpoints.b960)};
		transform: none;
		padding-left: ${props => props.theme.size.grid002};
		padding-${props => getStartDirection(props.theme.direction)}: ${props =>
    props.theme.size.grid002};
	}

	@media (min-width: ${Breakpoints.b1280}px) {
		width: ${getGridColumn(10)};
		max-width: ${getColumnsWidthForBreakpoint(10, Breakpoints.b1920)};
		padding-left: ${props => props.theme.size.grid001};
		padding-right: ${props => props.theme.size.grid001};
	}
`;

const StyledCaption = styled.div<StyledProps>`
    position: absolute;
    top: 0;
    left: ${props => props.theme.size.grid001};
    transform: translateY(-50%);
    background-color: ${props => props.theme.colors.background.primary};
    padding: 0 ${props => props.theme.size.static250};
`;

export interface FeatureClusterState {
    featuresWrapperHeight: number | null;
}

class InternalFeatureCluster extends React.Component<
    FeatureClusterProps,
    FeatureClusterState
> {
    private readonly featuresWrapperRef: React.RefObject<
        HTMLDivElement
    > = React.createRef();

    public readonly state: FeatureClusterState = {featuresWrapperHeight: null};

    private readonly measureAnimationContainer = (): void => {
        const container = ReactDOM.findDOMNode(this.featuresWrapperRef.current);

        if (container instanceof Element) {
            const rect = container.getBoundingClientRect();
            this.setState({featuresWrapperHeight: rect.height});
        }
    };

    public componentDidMount(): void {
        this.measureAnimationContainer();
    }

    public render(): JSX.Element {
        const {activeItem, items, caption} = this.props;
        const {featuresWrapperHeight} = this.state;
        const isExpanded = activeItem !== null;
        const showCaption = !isExpanded && caption;

        return (
            <Layout>
                <Container
                    wrap={{
                        [Breakpoints.default]: ContainerWrap.always,
                        [Breakpoints.b1280]: ContainerWrap.never
                    }}
                    padding={{
                        top: ContainerPadding.dynamic0150,
                        bottom: ContainerPadding.dynamic0150
                    }}
                    horizontalAlign={ContainerHorizontalAlignment.spaceBetween}
                    shrinkContent
                >
                    <Container
                        wrap={ContainerWrap.always}
                        gutter={ContainerGutter.dynamic0150}
                        padding={{
                            inlineStart: {
                                [Breakpoints.default]: ContainerPadding.grid002,
                                [Breakpoints.b960]: ContainerPadding.grid003,
                                [Breakpoints.b1280]: ContainerPadding.grid002
                            },
                            inlineEnd: {
                                [Breakpoints.default]: ContainerPadding.grid002,
                                [Breakpoints.b960]: ContainerPadding.grid008,
                                [Breakpoints.b1280]: ContainerPadding.grid002
                            },
                            top: {
                                [Breakpoints.b1280]:
                                    ContainerPadding.dynamic0270
                            }
                        }}
                    >
                        {this.props.children}
                    </Container>
                    <StyledAnimationContainer>
                        <StyledFeaturesWrapper
                            isExpanded={isExpanded}
                            ref={this.featuresWrapperRef}
                            minHeight={featuresWrapperHeight}
                        >
                            <ThemeContext.Consumer>
                                {value => {
                                    const direction = value.direction as Direction;

                                    return (
                                        <>
                                            {showCaption && (
                                                <StyledCaption>
                                                    <Fade
                                                        distance={
                                                            animationDistance
                                                        }
                                                        duration={
                                                            animationDuration
                                                        }
                                                        directionFrom={getStartDirection(
                                                            direction
                                                        )}
                                                        easing={animationEasing}
                                                    >
                                                        {caption}
                                                    </Fade>
                                                </StyledCaption>
                                            )}

                                            {activeItem !== null ? (
                                                <Fade
                                                    distance={animationDistance}
                                                    duration={animationDuration}
                                                    easing={animationEasing}
                                                    key="detail"
                                                    directionFrom={getEndDirection(
                                                        direction
                                                    )}
                                                >
                                                    {items[activeItem].detail}
                                                </Fade>
                                            ) : (
                                                <Fade
                                                    distance={animationDistance}
                                                    duration={animationDuration}
                                                    easing={animationEasing}
                                                    key="items"
                                                    directionFrom={getStartDirection(
                                                        direction
                                                    )}
                                                >
                                                    <Container
                                                        wrap={
                                                            ContainerWrap.always
                                                        }
                                                        gutter={
                                                            ContainerGutter.static350
                                                        }
                                                    >
                                                        {items.map(
                                                            item => item.label
                                                        )}
                                                    </Container>
                                                </Fade>
                                            )}
                                        </>
                                    );
                                }}
                            </ThemeContext.Consumer>
                        </StyledFeaturesWrapper>
                    </StyledAnimationContainer>
                </Container>
            </Layout>
        );
    }
}

export const FeatureCluster = withTheme(InternalFeatureCluster);
