import * as React from 'react';

import {CTA, styled, Breakpoints} from '@volkswagen-onehub/components-core';
import {ChevronDown} from '@volkswagen-onehub/icons-core';
import {AccordionSectionModel} from '../../../../generated/core';
import {AuthoringWrapper} from '../../../components/AuthoringWrapper';
import {useTrackingService} from '../../../context';
import {ContainerItem} from '../../../infrastructure/ContainerExporter';
import {MapTo} from '../../../infrastructure/compatibility/MapTo';
import {C} from '../../../registries/compatibilty';
import {getChildItems} from '../../../utils/container/getContainerChildItems';
import {Parsys, ParsysType} from '../../structure/Parsys';
import {ButtonElement} from '../elements/buttonElement/ButtonElement';
import {HeadingElement} from '../elements/HeadingElement';
import {LinkElement} from '../elements/LinkElementComponent';
import {AccordionItem} from '../items/AccordionItem';
import {SectionWrapper} from './SectionWrapper';
import {useContextTrackingData} from '../../../hooks/useContextTrackingData';
import {isInBrowser} from '../../../utils/browser/isInBrowser';

const PARSYS_PATH = 'accordionSectionParsys';
const DEFAULT_VISIBLE_ITEMS_COUNT = 4;

const headline = <HeadingElement path="heading" order="H2" />;

const buttonElement = <ButtonElement path="button" />;

const linkElement = <LinkElement path="link" />;

const StyledAccordionContainer = styled.div`
    --outerPadding: ${props => props.theme.size.grid002};
    --innerPadding: 0;

    display: grid;
    grid-auto-flow: row;
    grid-row-gap: ${props => props.theme.size.dynamic0100};
    grid-template-columns:
        var(--outerPadding) var(--innerPadding) 1fr var(--innerPadding)
        var(--outerPadding);

    @media (min-width: ${Breakpoints.b560}px) {
        --outerPadding: ${props => props.theme.size.grid004};
        --innerPadding: 0;
    }
    @media (min-width: ${Breakpoints.b1600}px) {
        --outerPadding: ${props => props.theme.size.grid006};
    }

    & :target {
        scroll-margin-top: 10vh;
    }
`;
StyledAccordionContainer.displayName = 'StyledAccordionContainer';

const StyledHeadlineWrapper = styled.div`
    grid-column: 2/5;
`;
StyledHeadlineWrapper.displayName = 'StyledHeadlineWrapper';

const StyledItemsWrapper = styled.div<{length?: number}>`
    grid-column: 3/4;

    &
        > *:nth-child(n
            + ${props => (props.length || DEFAULT_VISIBLE_ITEMS_COUNT) + 1}) {
        height: 0;
        overflow: hidden;
        visibility: hidden;
    }
`;
StyledItemsWrapper.displayName = 'StyledItemsWrapper';

const StyledButtonWrapper = styled.div`
    width: max-content;
    grid-column: 3/4;
`;
StyledButtonWrapper.displayName = 'StyledButtonWrapper';

const StyledAdditionalLinkWrapper = styled.div`
    width: max-content;
    grid-column: 3/4;
`;
StyledHeadlineWrapper.displayName = 'StyledHeadlineWrapper';

const getUrlHash = () =>
    isInBrowser() ? window.location.hash.slice(1) : undefined;

function InternalAccordionSection(props: AccordionSectionModel): JSX.Element {
    const trackingService = useTrackingService();
    const trackingData = useContextTrackingData();
    const [showAllItems, setShowAllItems] = React.useState(false);
    const [urlHash, setUrlHash] = React.useState(getUrlHash());

    const {
        anchorId,
        mboxId,
        cqItems,
        showAllButtonLabel,
        showAdditionalLink,
        buttonType,
        contentName,
        contentLabels,
        tagTypeBroken,
        contentId: sectionId
    } = props;
    const childItems = getChildItems(cqItems[PARSYS_PATH]);
    const showHeadline = C.isInEditor() || !cqItems.heading.empty;
    const additionalButtonType =
        buttonType === 'button' ? buttonElement : linkElement;
    const itemsToRenderLength =
        C.isInEditor() || showAllItems
            ? childItems.length
            : DEFAULT_VISIBLE_ITEMS_COUNT;

    React.useEffect(() => {
        if (!Boolean(urlHash) || showAllItems) {
            return;
        }
        const isAccordionHidden = childItems.find(
            (child, index) =>
                urlHash === child.model.anchorId &&
                index >= DEFAULT_VISIBLE_ITEMS_COUNT
        );
        if (!isAccordionHidden) {
            return;
        }
        setShowAllItems(true);
        // *should only render once*
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [urlHash]);

    function handleShowAllClick(): void {
        setShowAllItems(!showAllItems);
        trackingService.trackButtonClick(
            sectionId,
            window.location.pathname,
            showAllButtonLabel,
            trackingData
        );
    }

    React.useEffect(() => {
        function handleHashChange() {
            setUrlHash(getUrlHash());
        }
        window.addEventListener('hashchange', handleHashChange);

        return function cleanUp() {
            window.removeEventListener('hashchange', handleHashChange);
        };
    }, []);

    return (
        <AuthoringWrapper
            anchorId={anchorId}
            title="S117 Accordion Section"
            bgColor={AuthoringWrapper.BG_COLOR_SECTION}
            tagTypeBroken={tagTypeBroken}
        >
            <SectionWrapper
                mboxId={mboxId}
                anchorId={anchorId}
                sectionId={sectionId}
                contentLabels={contentLabels}
                contentName={contentName}
            >
                <StyledAccordionContainer>
                    <StyledHeadlineWrapper>
                        {showHeadline && headline}
                    </StyledHeadlineWrapper>
                    <StyledItemsWrapper length={itemsToRenderLength}>
                        {C.isInEditor() ? (
                            <Parsys
                                path={PARSYS_PATH}
                                parsysType={ParsysType.ITEM_PARSYS}
                            />
                        ) : (
                            childItems.map((child: ContainerItem) => (
                                <AccordionItem
                                    path={`${PARSYS_PATH}/${child.key}`}
                                    key={child.key}
                                    defaultOpen={
                                        child.model.defaultOpen ||
                                        urlHash === child.model.anchorId
                                    }
                                    forceOpen={urlHash === child.model.anchorId}
                                />
                            ))
                        )}
                    </StyledItemsWrapper>
                    <StyledButtonWrapper>
                        {!showAllItems &&
                            childItems.length > DEFAULT_VISIBLE_ITEMS_COUNT && (
                                <CTA
                                    tag="button"
                                    tabIndex={0}
                                    emphasis="secondary"
                                    icon={
                                        <ChevronDown
                                            variant="small"
                                            ariaHidden
                                        />
                                    }
                                    onClick={handleShowAllClick}
                                >
                                    {showAllButtonLabel}
                                </CTA>
                            )}
                    </StyledButtonWrapper>
                    {showAdditionalLink && (
                        <StyledAdditionalLinkWrapper>
                            {additionalButtonType}
                        </StyledAdditionalLinkWrapper>
                    )}
                </StyledAccordionContainer>
            </SectionWrapper>
        </AuthoringWrapper>
    );
}

export const AccordionSection = MapTo(
    'vwa-ngw18/components/editorial/sections/accordionSection',
    InternalAccordionSection
);
