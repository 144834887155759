import * as React from 'react';
import {NewsletterSignupSectionModel} from '../../../../generated/core';
import {AuthoringWrapper} from '../../../components/AuthoringWrapper';
import {GeneralDisclaimerProvider} from '../../../context/disclaimer/GeneralDisclaimerProvider';
import {NewsletterSignupComponent} from '../../../d6/components/newsletter-signup-section';
import {MapTo} from '../../../infrastructure/compatibility/MapTo';
import {ButtonElement} from '../elements/buttonElement/ButtonElement';
import {HeadingElement} from '../elements/HeadingElement';
import {CopyItem} from '../items/CopyItem';
import {SectionWrapper} from './SectionWrapper';

const InternalNewsletterSignupSection = (
    props: NewsletterSignupSectionModel
): JSX.Element => {
    const {
        contentId: sectionId,
        anchorId,
        tagTypeBroken,
        contentLabels,
        contentName,
        mboxId
    } = props;

    const headline = <HeadingElement path="heading" style="H1" order="H2" />;
    const copy = <CopyItem path="copy" />;
    const buttonLink = <ButtonElement path="button" />;

    return (
        <AuthoringWrapper
            anchorId={anchorId}
            title="S119 Newsletter Signup Section"
            bgColor={AuthoringWrapper.BG_COLOR_SECTION}
            tagTypeBroken={tagTypeBroken}
        >
            <SectionWrapper
                mboxId={mboxId}
                anchorId={anchorId}
                sectionId={sectionId}
                contentLabels={contentLabels}
                contentName={contentName}
            >
                <GeneralDisclaimerProvider displayTypes={['T3_SECTION_BASED']}>
                    <NewsletterSignupComponent
                        headline={headline}
                        copy={copy}
                        link={buttonLink}
                    />
                </GeneralDisclaimerProvider>
            </SectionWrapper>
        </AuthoringWrapper>
    );
};

export const NewsletterSignupSection = MapTo(
    'vwa-ngw18/components/editorial/sections/newsletterSignupSection',
    InternalNewsletterSignupSection
);
