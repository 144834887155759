import * as React from 'react';

import {CountdownSectionModel} from '../../../../generated/core';
import {AlertBox, AlertBoxSeverity} from '../../../components/AlertBox';
import {AuthoringWrapper} from '../../../components/AuthoringWrapper';
import {MapTo} from '../../../infrastructure/compatibility/MapTo';
import {C} from '../../../registries/compatibilty';
import {CountdownTeaserElement} from '../elements/CountdownTeaserElement';
import {PreBookingTeaserElement} from '../elements/PreBookingTeaserElement';
import {SectionWrapper} from './SectionWrapper';

interface CountdownSectionState {
    displayType: 'PREBOOKING' | 'COUNTDOWN_AND_PREBOOKING';
}

class CountdownSection extends React.Component<
    CountdownSectionModel,
    CountdownSectionState
> {
    public readonly state: CountdownSectionState = {
        displayType: this.props.typeToShow
    };
    private intervalId: number | null = null;

    public componentDidMount(): void {
        if (
            this.state.displayType === 'COUNTDOWN_AND_PREBOOKING' &&
            new Date(this.props.startOfPreOrder) > new Date()
        ) {
            this.intervalId = window.setInterval(this.handleTime, 1000);
            this.handleTime();
        } else {
            this.setState({displayType: 'PREBOOKING'});
        }
    }

    public componentWillUnmount(): void {
        this.cancelInterval();
    }

    public render(): JSX.Element {
        const {
            anchorId,
            contentId: sectionId,
            contentName,
            contentLabels,
            startOfPreOrder,
            prebookingAndCountdownType: moduleType,
            tagTypeBroken,
            mboxId
        } = this.props;
        const preOrderDate = new Date(startOfPreOrder);

        const preBooking = (
            <PreBookingTeaserElement path="preBookingTeaserElement" />
        );

        const countDown = (
            <CountdownTeaserElement
                path="countdownTeaserElement"
                preOrderDate={preOrderDate}
            />
        );

        const certainModuleBasedOnTime =
            this.state.displayType === 'PREBOOKING' ? preBooking : countDown;

        const bothModules = C.isInEditor() ? (
            <>
                {startOfPreOrder === 0 ? (
                    <AlertBox severity={AlertBoxSeverity.ERROR}>
                        <span>Please set the Pre Order Date</span>
                    </AlertBox>
                ) : (
                    <AlertBox severity={AlertBoxSeverity.INFO}>
                        <span>
                            Countdown date set to: {preOrderDate.toString()}
                        </span>
                    </AlertBox>
                )}
                {countDown}
                {preBooking}
            </>
        ) : (
            certainModuleBasedOnTime
        );

        return (
            <AuthoringWrapper
                anchorId={anchorId}
                title="S118 Countdown Section"
                bgColor={AuthoringWrapper.BG_COLOR_SECTION}
                tagTypeBroken={tagTypeBroken}
            >
                <SectionWrapper
                    mboxId={mboxId}
                    anchorId={anchorId}
                    sectionId={sectionId}
                    contentLabels={contentLabels}
                    key={this.state.displayType}
                    contentName={contentName}
                >
                    {moduleType === 'COUNTDOWN_AND_PREBOOKING'
                        ? bothModules
                        : preBooking}
                </SectionWrapper>
            </AuthoringWrapper>
        );
    }

    private cancelInterval = (): void => {
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
    };

    private handleTime = (): void => {
        if (this.props.startOfPreOrder < new Date().getTime()) {
            this.cancelInterval();
            this.setState({
                displayType: 'PREBOOKING'
            });
        }
    };
}

MapTo(
    'vwa-ngw18/components/editorial/sections/countdownSection',
    CountdownSection
);
