// Slugify, taken from:
// https://medium.com/@christian.sarnataro/what-about-transliterations-like-ae55d22c35f5
export const slugify = (text: string) => {
    const a = 'àáäâãåăæçčèéëêěǵḧìíïîḿńǹñňòóöôœṕŕřßśșšțťùúüûůǘẃẍÿýźž·/_,:;';
    const b = 'aaaaaaaacceeeeeghiiiimnnnnoooooprrssssttuuuuuuwxyyzz------';
    const p = new RegExp(a.split('').join('|'), 'g');

    return text
        .toString()
        .toLowerCase()
        .replace(/\s+/g, '-') // Replace spaces with -
        .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
        .replace(/&/g, '-and-') // Replace & with 'and'
        .replace(/[^\w-]+/g, '') // Remove all non-word characters
        .replace(/--+/g, '-') // Replace multiple - with single -
        .replace(/^-+/, '') // Trim - from start of text
        .replace(/-+$/, ''); // Trim - from end of text
};
