import * as React from 'react';

import {
    Breakpoints,
    Text,
    TokenTextAppearance,
    styled
} from '@volkswagen-onehub/components-core';
import {StepByStepItem as StepByStepItemComp} from '../../../d6/components/step-by-step';
import {StepByStepItemModel} from '../../../../generated/core';
import {AuthoringWrapper} from '../../../components/AuthoringWrapper';
import {
    ResponsiveMediaRenderer,
    ResponsiveMediaRendererConf
} from '../../../components/ResponsiveMediaRenderer';
import {MapTo} from '../../../infrastructure/compatibility/MapTo';
import {C} from '../../../registries/compatibilty';
import {CyAttributeAppender} from '../../../test/CyAttributeAppender';
import {MediaElement} from '../elements/MediaElement';
import {
    RichtextFullElement,
    RichtextSimpleElement
} from '../elements/RichtextElement';

export type StepByStepItemProps = StepByStepItemModel &
    StepByStepItemExtraProps;

export interface StepByStepItemExtraProps {
    readonly isFirstChild?: boolean;
    readonly stepId?: string;
}

// TODO this has to be removed after the disclaimer ref positioning is fixed
export const StyledImageWrapper = styled.div`
    position: relative;
`;

const responsiveMediaConfiguration: ResponsiveMediaRendererConf[] = [
    {
        mediaQuery: `(max-width: ${Breakpoints.b1600 - 1}px)`,
        aspectRatio: 'r_16_9',
        sizes: '70vw'
    },
    {
        mediaQuery: `(min-width: ${Breakpoints.b1600}px)`,
        aspectRatio: 'r_16_9',
        sizes: '25vw'
    }
];

/**
 * StepByStepItem
 */
class InternalStepByStepItem extends React.Component<StepByStepItemProps> {
    public render(): JSX.Element {
        const {isFirstChild, stepId, hasDescription, hasImage} = this.props;

        const media = (
            <CyAttributeAppender name="stepMedia">
                <StyledImageWrapper>
                    <ResponsiveMediaRenderer
                        configs={responsiveMediaConfiguration}
                        matchParent
                    >
                        <MediaElement
                            path="media"
                            containerProps={{matchParent: true}}
                            responsiveMediaConfig={responsiveMediaConfiguration}
                        />
                    </ResponsiveMediaRenderer>
                </StyledImageWrapper>
            </CyAttributeAppender>
        );

        const caption = (
            <RichtextSimpleElement
                path="caption"
                appearance={TokenTextAppearance.headline300}
            />
        );

        const description = (
            <CyAttributeAppender name="stepDescription">
                <RichtextFullElement path="description" />
            </CyAttributeAppender>
        );

        return C.isInEditor() ? (
            <AuthoringWrapper
                title="I111 Step By Step ContainerChild"
                bgColor={AuthoringWrapper.BG_COLOR_ITEM}
            >
                <Text appearance={TokenTextAppearance.super200}>{stepId}</Text>
                {media}
                {caption}
                {description}
            </AuthoringWrapper>
        ) : (
            <StepByStepItemComp
                stepId={stepId}
                media={hasImage && media}
                caption={caption}
                isFirstChild={isFirstChild}
                description={hasDescription && description}
            />
        );
    }
}

export const StepByStepItem = MapTo<StepByStepItemExtraProps>(
    'vwa-ngw18/components/editorial/items/stepByStepItem',
    InternalStepByStepItem
);
