import * as React from 'react';

import {
    Container,
    ContainerGutter,
    ContainerWrap,
    Text,
    TokenTextAppearance,
    TextTag,
    styled
} from '@volkswagen-onehub/components-core';

const Divider = styled.div`
    width: 2px;
    height: calc(100% - ${props => props.theme.fonts.copy[200].fontSize});
    background-color: ${props => props.theme.text.color.primary};
`;

Divider.displayName = 'Divider';

const ONE_SECOND_IN_MILLISECONDS = 1000;

const convertTime = (milliseconds: number): ConvertedTime => {
    let seconds = Math.floor(milliseconds / ONE_SECOND_IN_MILLISECONDS);
    let minutes = Math.floor(seconds / 60);
    seconds = seconds % 60;
    let hours = Math.floor(minutes / 60);
    minutes = minutes % 60;
    const days = Math.floor(hours / 24);
    hours = hours % 24;

    const convertedTime = {
        days,
        hours,
        minutes,
        seconds
    };

    return convertedTime;
};

export interface CountdownProps {
    readonly dayLabel: string;
    readonly daysLabel: string;
    readonly timeLabel: string;
    readonly countDownTime: number;
}

interface ConvertedTime {
    readonly seconds: number;
    readonly minutes: number;
    readonly hours: number;
    readonly days: number;
}

export class Countdown extends React.Component<CountdownProps> {
    private interval: number | undefined;

    public componentDidMount(): void {
        this.countDown();
    }

    public componentWillUnmount(): void {
        if (this.interval) {
            clearInterval(this.interval);
        }
    }

    public readonly countDown = () => {
        this.interval = window.setInterval(() => {
            this.forceUpdate();
        }, ONE_SECOND_IN_MILLISECONDS);
    };

    public render(): JSX.Element {
        const {dayLabel, daysLabel, timeLabel, countDownTime} = this.props;

        const actualTime = Date.now();
        const timeRemaining =
            countDownTime > actualTime
                ? countDownTime - actualTime
                : ONE_SECOND_IN_MILLISECONDS;

        if (timeRemaining <= ONE_SECOND_IN_MILLISECONDS && this.interval) {
            clearInterval(this.interval);
        }

        const {days, hours, minutes, seconds} = convertTime(timeRemaining);

        const addLeadingZero = (value: number) => `0${value}`.slice(-2);

        const dayCountdown = (
            <Container wrap={ContainerWrap.always}>
                <Text
                    appearance={TokenTextAppearance.headline600}
                    tag={TextTag.span}
                    bold
                >
                    {addLeadingZero(days)}
                </Text>

                <Text
                    appearance={TokenTextAppearance.copy200}
                    tag={TextTag.span}
                >
                    {days === 1 ? dayLabel : daysLabel}
                </Text>
            </Container>
        );

        const timeCountdown = (
            <Container>
                <Container wrap={ContainerWrap.always}>
                    <Text
                        appearance={TokenTextAppearance.headline600}
                        tag={TextTag.span}
                    >
                        {addLeadingZero(hours)}:
                    </Text>

                    <Text
                        appearance={TokenTextAppearance.copy200}
                        tag={TextTag.span}
                    >
                        {timeLabel}
                    </Text>
                </Container>
                <Text
                    appearance={TokenTextAppearance.headline600}
                    tag={TextTag.span}
                >
                    {addLeadingZero(minutes)}:{addLeadingZero(seconds)}
                </Text>
            </Container>
        );

        return (
            <Container
                gutter={ContainerGutter.dynamic0050}
                wrap={ContainerWrap.never}
            >
                {dayCountdown}
                <Divider />
                {timeCountdown}
            </Container>
        );
    }
}
