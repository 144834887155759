import * as React from 'react';

import {CTA} from '@volkswagen-onehub/components-core';

import {GlossaryAnchorLinkModel} from '../../../../../generated/core';

type GlossaryAnchorLinkComponentExtraProps = {
    isFocused: boolean;
};

export const GlossaryAnchorLinkComponent: React.FunctionComponent<GlossaryAnchorLinkModel &
    GlossaryAnchorLinkComponentExtraProps> = props => {
    const {title, href, empty, isFocused} = props;
    const ref = React.createRef<HTMLAnchorElement | HTMLButtonElement>();

    const handleFocus = () => {
        return ref?.current?.focus();
    };

    React.useEffect(() => {
        if (isFocused) {
            handleFocus();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref, isFocused]);

    return empty ? (
        <CTA tag="a" emphasis="none" href={href} disabled>
            {title}
        </CTA>
    ) : (
        <CTA tag="a" emphasis="tertiary" href={href} ref={ref}>
            {title}
        </CTA>
    );
};
