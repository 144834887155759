import {Breakpoints} from '@volkswagen-onehub/components-core';

const breakpointMap: {[key: string]: Breakpoints} = {
    default: Breakpoints.default,
    b560: Breakpoints.b560,
    b960: Breakpoints.b960,
    b1280: Breakpoints.b1280,
    b1600: Breakpoints.b1600,
    b1920: Breakpoints.b1920,
    b2560: Breakpoints.b2560
};

export function getBreakpointValue(breakpointName: string): number {
    return breakpointMap.hasOwnProperty(breakpointName)
        ? breakpointMap[breakpointName]
        : Breakpoints.default;
}
