import {
    Text,
    TokenTextAppearance,
    styled
} from '@volkswagen-onehub/components-core';
import * as React from 'react';
import {AnchorTarget} from '../AnchorTarget';

export const GLOSSARY_ID = 'glossary-letters';

const StyledGlossary = styled.nav`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
`;
StyledGlossary.displayName = 'StyledGlossary';

const StyledGlossaryList = styled.ul`
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
`;
StyledGlossaryList.displayName = 'StyledGlossaryList';

const StyledItem = styled.li`
    a {
        display: inline-block;
        padding: 0 ${props => props.theme.size.static150};
    }
`;
StyledItem.displayName = 'StyledItem';

interface GlossaryProps {
    title: string;
}

export const Glossary = (
    props: React.PropsWithChildren<GlossaryProps>
): JSX.Element => {
    const {children, title} = props;

    return (
        <AnchorTarget anchorId={GLOSSARY_ID}>
            <StyledGlossary title={title}>
                <StyledGlossaryList>
                    {React.Children.map(children, child => (
                        <StyledItem>
                            <Text
                                appearance={TokenTextAppearance.bigcopy100}
                                bold
                            >
                                {child}
                            </Text>
                        </StyledItem>
                    ))}
                </StyledGlossaryList>
            </StyledGlossary>
        </AnchorTarget>
    );
};

Glossary.displayName = 'Glossary';
