import * as React from 'react';
import styled from 'styled-components';

import {PlainHTMLSectionModel} from '../../../../generated/core';
import {RICHTEXT_AUTHOR_MIN_WIDTH} from '../../../components/Richtext';
import {MapTo} from '../../../infrastructure/compatibility/MapTo';
import {C} from '../../../registries/compatibilty';
import {TextWithNonBreakingSafewords} from '../../../utils/TextWithNonBreakingSafewords';

const StyledAuthorPlainHTML = styled.span`
    min-width: ${RICHTEXT_AUTHOR_MIN_WIDTH};
`;

export class InternalPlainHTMLSection extends React.PureComponent<
    PlainHTMLSectionModel
> {
    private decode(encodedString: string): string {
        if (!encodedString) {
            return encodedString;
        }

        return encodedString
            .replace(/&(nbsp|amp|quot|lt|gt);/g, function(
                _match,
                entity: string
            ) {
                switch (entity) {
                    case 'nbsp':
                        return ' ';
                    case 'amp':
                        return '&';
                    case 'quot':
                        return '"';
                    case 'lt':
                        return '<';
                    case 'gt':
                        return '>';
                    default:
                        return '';
                }
            })
            .replace(/&#(\d+);/g, function(_match, dec) {
                return String.fromCharCode(dec);
            });
    }

    public render(): JSX.Element {
        const {text} = this.props;

        let textElement = (
            <React.Fragment>
                <TextWithNonBreakingSafewords
                    __content_is_html_and_i_know_for_sure_it_is_safe_and_wont_cause_xss_vulnerability={
                        true
                    }
                >
                    {this.decode(text) ||
                        (C.isInEditor() ? 'Enter your code here' : '')}
                </TextWithNonBreakingSafewords>
            </React.Fragment>
        );

        if (C.isInEditor()) {
            textElement = (
                <StyledAuthorPlainHTML>{textElement}</StyledAuthorPlainHTML>
            );
        }
        return textElement;
    }
}

export const PlainHTMLSection = MapTo<{}>(
    'vwa-ngw18/components/editorial/sections/plainHTMLSection',
    InternalPlainHTMLSection
);
