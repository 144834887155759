import * as React from 'react';
import styled from 'styled-components';

import {
    Breakpoints,
    Container,
    ContainerPadding,
    Layout,
    ThemeProvider,
    ContainerWrap
} from '@volkswagen-onehub/components-core';

import {CountdownTeaserElementModel} from '../../../../generated/core';
import {AuthoringWrapper} from '../../../components/AuthoringWrapper';
import {
    ResponsiveMediaRenderer,
    ResponsiveMediaRendererConf
} from '../../../components/ResponsiveMediaRenderer';
import {OverlayDisclaimers} from '../../../components/disclaimers/OverlayDisclaimerContainers';
import {MapTo} from '../../../infrastructure/compatibility/MapTo';
import {C} from '../../../registries/compatibilty';
import {CopyItem} from '../items/CopyItem';
import {ButtonElement} from './buttonElement/ButtonElement';
import {Countdown as CountdownLSG} from './CountdownElement';
import {HeadingElement} from './HeadingElement';
import {MediaElement} from './MediaElement';
import {GeneralDisclaimerProvider} from '../../../context/disclaimer/GeneralDisclaimerProvider';

export type CountdownTeaserElementProps = CountdownTeaserElementModel &
    CountdownTeaserElementExtraProps;

export interface CountdownTeaserElementExtraProps {
    preOrderDate?: Date;
}

export const StyledCountdownContainer = styled.div`
    padding: ${props => props.theme.size.dynamic0270}
        ${props => props.theme.size.grid002} 0;

    @media (min-width: ${Breakpoints.b560}px) {
        padding: ${props => props.theme.size.dynamic0270}
            ${props => props.theme.size.grid001} 0;
    }

    @media (min-width: ${Breakpoints.b1600}px) {
        padding: ${props => props.theme.size.dynamic0270}
            ${props => props.theme.size.grid002} 0;
    }
`;

StyledCountdownContainer.displayName = 'StyledCountdownContainer';

export const StyledStageWrapper = styled.div`
    position: relative;
    min-height: 160vh;

    @media (min-width: ${Breakpoints.b560}px) {
        min-height: 100%;
    }
`;

StyledStageWrapper.displayName = 'StyledStageWrapper';

export const StyledContentWrapper = styled.div`
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;

    @media (min-width: ${Breakpoints.b560}px) {
        position: relative;
    }
`;

StyledContentWrapper.displayName = 'StyledContentWrapper';

export const StyledMediaWrapper = styled.div`
    position: absolute;
    height: 100%;
    width: 100%;
`;

StyledMediaWrapper.displayName = 'StyledMediaWrapper';

const layoutConfigurationHalfSwitched = {
    [Breakpoints.default]: [
        {name: 'a', columns: 24},
        {name: 'b', columns: 24}
    ],
    [Breakpoints.b560]: [
        {name: 'b', columns: 12},
        {name: 'a', columns: 12}
    ]
};

export const responsiveCountdownMediaConfig: ResponsiveMediaRendererConf[] = [
    {
        mediaQuery: `(max-width: ${Breakpoints.b560 - 1}px)`,
        aspectRatio: 'r_9_16',
        sizes: '200vw'
    },
    {
        mediaQuery: `(min-width: ${
            Breakpoints.b560
        }px) and (max-width: ${Breakpoints.b960 - 1}px)`,
        aspectRatio: 'r_4_3',
        sizes: '100vw'
    },
    {
        mediaQuery: `(min-width: ${Breakpoints.b960}px)`,
        aspectRatio: 'r_16_9',
        sizes: '100vw'
    }
];

class InternalCountdownTeaserElement extends React.Component<
    CountdownTeaserElementProps
> {
    public render(): JSX.Element {
        const {dayLabel, daysLabel, timeLabel} = this.props;

        const inEditor = C.isInEditor();

        const mediaElement = (
            <MediaElement
                path="media"
                containerProps={{matchParent: true}}
                useParentAspectRatio={true}
                hideItemBasedDisclaimers={!inEditor}
                responsiveMediaConfig={responsiveCountdownMediaConfig}
            />
        );

        const wrappedMediaElement = (
            <ResponsiveMediaRenderer configs={responsiveCountdownMediaConfig}>
                <StyledMediaWrapper>{mediaElement}</StyledMediaWrapper>
            </ResponsiveMediaRenderer>
        );

        const countdownHeadingElement = (
            <HeadingElement
                path="heading"
                hideItemDisclaimers
                style="H2"
                order="H2"
            />
        );

        const countdownCopyItem = <CopyItem path="copy" hideItemDisclaimers />;

        const buttonLink = <ButtonElement path="button" />;

        return (
            <AuthoringWrapper
                title="Countdown Element"
                bgColor={AuthoringWrapper.BG_COLOR_SECTION}
            >
                <ThemeProvider theme={inEditor ? 'main' : 'inverted'}>
                    <StyledStageWrapper>
                        <GeneralDisclaimerProvider
                            displayTypes={['T3_SECTION_BASED', 'T4_ITEM_BASED']}
                        >
                            <>
                                {!inEditor && wrappedMediaElement}
                                <StyledContentWrapper>
                                    <Layout
                                        appearance={
                                            layoutConfigurationHalfSwitched
                                        }
                                        allowOverflowingContent
                                    >
                                        <StyledCountdownContainer>
                                            <Container
                                                padding={{
                                                    bottom:
                                                        ContainerPadding.dynamic0100
                                                }}
                                                wrap={ContainerWrap.always}
                                            >
                                                {countdownHeadingElement}
                                            </Container>
                                            <Container
                                                padding={{
                                                    bottom:
                                                        ContainerPadding.dynamic0150
                                                }}
                                                wrap={ContainerWrap.always}
                                            >
                                                {countdownCopyItem}
                                            </Container>
                                            {this.props.preOrderDate && (
                                                <Container>
                                                    <CountdownLSG
                                                        dayLabel={dayLabel}
                                                        daysLabel={daysLabel}
                                                        timeLabel={timeLabel}
                                                        countDownTime={this.props.preOrderDate.getTime()}
                                                    />
                                                </Container>
                                            )}
                                            <Container
                                                padding={{
                                                    top:
                                                        ContainerPadding.dynamic0250,
                                                    bottom:
                                                        ContainerPadding.dynamic0300
                                                }}
                                            >
                                                {buttonLink}
                                            </Container>
                                        </StyledCountdownContainer>
                                        {inEditor ? mediaElement : <></>}
                                        {/* needed for the positioning of containers in two column layout */}
                                    </Layout>
                                </StyledContentWrapper>
                                {inEditor ? null : (
                                    <OverlayDisclaimers
                                        displayTypes={[
                                            'T3_SECTION_BASED',
                                            'T4_ITEM_BASED'
                                        ]}
                                        color={'light'}
                                        type="item"
                                    />
                                )}
                            </>
                        </GeneralDisclaimerProvider>
                    </StyledStageWrapper>
                </ThemeProvider>
            </AuthoringWrapper>
        );
    }
}

export const CountdownTeaserElement = MapTo<CountdownTeaserElementExtraProps>(
    'vwa-ngw18/components/editorial/elements/countdownTeaserElement',
    InternalCountdownTeaserElement
);
