import {Breakpoints, ThemeProvider} from '@volkswagen-onehub/components-core';
import * as React from 'react';
import styled from 'styled-components';

import {GradientBackground} from '../gradient-background';

export interface NewsletterSignupComponentProps {
    readonly headline: JSX.Element;
    readonly copy: JSX.Element;
    readonly link: JSX.Element;
}

const NewsletterSignupSectionWraper = styled.div`
    padding-top: ${props => props.theme.size.dynamic0250};
    padding-bottom: ${props => props.theme.size.dynamic0250};
    text-align: center;
`;
NewsletterSignupSectionWraper.displayName = 'NewsletterSignupSectionWraper';

const NewsletterSignupContentWrapper = styled.div`
    margin: 0 auto;
`;
NewsletterSignupContentWrapper.displayName = 'NewsletterSignupContentWrapper';

const HeadlineWrapper = styled.div`
    padding-bottom: ${props => props.theme.size.dynamic0100};
    padding-left: ${props => props.theme.size.grid002};
    padding-right: ${props => props.theme.size.grid002};

    @media (min-width: ${Breakpoints.b560}px) {
        padding-left: ${props => props.theme.size.grid005};
        padding-right: ${props => props.theme.size.grid005};
    }
    @media (min-width: ${Breakpoints.b960}px) {
        padding-left: ${props => props.theme.size.grid006};
        padding-right: ${props => props.theme.size.grid006};
    }
    @media (min-width: ${Breakpoints.b1280}px) {
        padding-left: ${props => props.theme.size.grid007};
        padding-right: ${props => props.theme.size.grid007};
    }
    @media (min-width: ${Breakpoints.b1920}px) {
        padding-left: ${props => props.theme.size.grid008};
        padding-right: ${props => props.theme.size.grid008};
    }
`;
HeadlineWrapper.displayName = 'HeadlineWrapper';

const CopyWrapper = styled.div`
    padding-bottom: ${props => props.theme.size.dynamic0130};
    padding-left: ${props => props.theme.size.grid002};
    padding-right: ${props => props.theme.size.grid002};

    @media (min-width: ${Breakpoints.b560}px) {
        padding-left: ${props => props.theme.size.grid004};
        padding-right: ${props => props.theme.size.grid004};
    }
    @media (min-width: ${Breakpoints.b960}px) {
        padding-left: ${props => props.theme.size.grid005};
        padding-right: ${props => props.theme.size.grid005};
    }
    @media (min-width: ${Breakpoints.b1280}px) {
        padding-left: ${props => props.theme.size.grid007};
        padding-right: ${props => props.theme.size.grid007};
    }
    @media (min-width: ${Breakpoints.b1920}px) {
        padding-left: ${props => props.theme.size.grid008};
        padding-right: ${props => props.theme.size.grid008};
    }
`;
CopyWrapper.displayName = 'CopyWrapper';

export const NewsletterSignupComponent: React.FunctionComponent<NewsletterSignupComponentProps> = props => (
    <ThemeProvider theme="inverted">
        <GradientBackground centerX={50} centerY={85}>
            <NewsletterSignupSectionWraper>
                <NewsletterSignupContentWrapper>
                    <HeadlineWrapper>{props.headline}</HeadlineWrapper>
                    <CopyWrapper>{props.copy}</CopyWrapper>
                    {props.link}
                </NewsletterSignupContentWrapper>
            </NewsletterSignupSectionWraper>
        </GradientBackground>
    </ThemeProvider>
);
