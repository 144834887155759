import * as React from 'react';
import styled, {ThemeContext} from 'styled-components';

import {Direction, getStartDirection, resetButtonStyles} from '../helpers';

import {
    Container,
    ContainerHorizontalAlignment,
    ContainerVerticalAlignment,
    ContainerWrap
} from '@volkswagen-onehub/components-core';

import {ChevronLeft, ChevronRight} from '@volkswagen-onehub/icons-core';

const focusOutlineWidth = 2;
const focusOutlineOffset = 5;

const StyledListItemButton = styled.button`
    ${resetButtonStyles}
    color: ${props => props.theme.colors.button.primary.default};
`;

const StyledFeatureClusterListItem = styled.div`
    width: 100%;
    cursor: pointer;
    position: relative;

    & > button {
        width: 100%;

        :focus {
            outline: ${focusOutlineWidth}px solid
                ${props => props.theme.colors.button.primary.active};
            outline-offset: ${focusOutlineOffset}px;
        }

        & > div > div > div:first-child {
            flex: 1;
            text-align: ${props => getStartDirection(props.theme.direction)};
        }
    }
`;
StyledFeatureClusterListItem.displayName = 'StyledFeatureClusterListItem';

export type FeatureListItemProps = React.PropsWithChildren<{
    onClick(): void;
}>;

export function FeatureClusterListItem(
    props: FeatureListItemProps
): JSX.Element {
    const {children, onClick} = props;

    return (
        <StyledFeatureClusterListItem>
            <StyledListItemButton onClick={onClick}>
                <Container
                    wrap={ContainerWrap.never}
                    horizontalAlign={ContainerHorizontalAlignment.spaceBetween}
                    verticalAlign={ContainerVerticalAlignment.center}
                >
                    {children}
                    <ThemeContext.Consumer>
                        {value =>
                            value.direction !== Direction.RTL ? (
                                <ChevronRight variant="small" ariaHidden />
                            ) : (
                                <ChevronLeft variant="small" ariaHidden />
                            )
                        }
                    </ThemeContext.Consumer>
                </Container>
            </StyledListItemButton>
        </StyledFeatureClusterListItem>
    );
}
