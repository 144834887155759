import * as React from 'react';

import {
    Container,
    ContainerWrap,
    Layout
} from '@volkswagen-onehub/components-core';
import {getContainerPadding} from '../../../d6/components/helpers';

import {GlossarySectionModel} from '../../../../generated/core';
import {AuthoringWrapper} from '../../../components/AuthoringWrapper';
import {Glossary} from '../../../components/glossary/Glossary';
import {MapTo} from '../../../infrastructure/compatibility/MapTo';
import {SectionWrapper} from './SectionWrapper';
import {GlossaryAnchorLinkComponent} from './glossary/GlossaryAnchorLinkComponent';
import {GlossaryGroup} from './glossary/GlossaryGroup';
import styled from 'styled-components';
import {GeneralDisclaimerProvider} from '../../../context/disclaimer/GeneralDisclaimerProvider';

const LABEL = 'S121 Glossary Section';

const CONTAINER_PADDING_1_2 = getContainerPadding([1, 2]);

const StyledDefinitionList = styled.dl`
    display: flex;
    flex-direction: column;
    margin: 0;
`;

const StyledGlossaryGroupWrapper = styled.div`
    & > div {
        padding-top: ${props => props.theme.size.dynamic0350};
    }
`;

/**
 * S120 Glossary Section
 */
const InternalGlossarySection = (props: GlossarySectionModel): JSX.Element => {
    const {
        anchorId,
        mboxId,
        contentId: sectionId,
        contentLabels,
        contentName,
        lettersNavTitle,
        goBackButtonText,
        groups = [],
        links = []
    } = props;
    const [focusedIndex, setFocusedIndex] = React.useState<number | null>(null);
    const glossaryItems = links.map((link, idx) => (
        <GlossaryAnchorLinkComponent
            key={link.href}
            {...link}
            isFocused={idx === focusedIndex}
        />
    ));

    const focusGlossaryItemLetter = (index: number) => {
        setFocusedIndex(index);
    };

    return (
        <AuthoringWrapper
            anchorId={anchorId}
            title={LABEL}
            bgColor={AuthoringWrapper.BG_COLOR_SECTION}
        >
            <SectionWrapper
                mboxId={mboxId}
                anchorId={anchorId}
                sectionId={sectionId}
                contentLabels={contentLabels}
                contentName={contentName}
            >
                <GeneralDisclaimerProvider displayTypes={['T3_SECTION_BASED']}>
                    <Layout>
                        <Container
                            padding={{
                                left: CONTAINER_PADDING_1_2,
                                right: CONTAINER_PADDING_1_2
                            }}
                            wrap={ContainerWrap.always}
                        >
                            <Glossary key="glossary" title={lettersNavTitle}>
                                {glossaryItems}
                            </Glossary>
                        </Container>
                        <StyledDefinitionList>
                            {groups.map((group, idx) => (
                                <StyledGlossaryGroupWrapper key={group.letter}>
                                    <GlossaryGroup
                                        goBackButtonText={goBackButtonText}
                                        key={group.letter}
                                        letter={group.letter}
                                        anchorTargetId={group.anchorTargetId}
                                        glossaryItems={group.glossaryItems}
                                        onBackButtonClick={() =>
                                            focusGlossaryItemLetter(idx)
                                        }
                                    />
                                </StyledGlossaryGroupWrapper>
                            ))}
                        </StyledDefinitionList>
                    </Layout>
                </GeneralDisclaimerProvider>
            </SectionWrapper>
        </AuthoringWrapper>
    );
};

export const sectionPathParsysIdentifier = '/mainParsys/glossarySection/';

export const GlossarySection = MapTo(
    'vwa-ngw18/components/editorial/sections/glossarySection',
    InternalGlossarySection
);
