import * as React from 'react';

import {
    Container,
    ContainerGutter,
    ContainerPadding,
    ContainerWrap,
    Layout
} from '@volkswagen-onehub/components-core';
import {getContainerPadding} from '../../../d6/components/helpers';
import styled from 'styled-components';

import {GlossaryItemModel} from '../../../../generated/core';
import {AnchorTarget} from '../../../components/AnchorTarget';
import {AuthoringWrapper} from '../../../components/AuthoringWrapper';
import {MapTo} from '../../../infrastructure/compatibility/MapTo';
import {C} from '../../../registries/compatibilty';
import {slugify} from '../../../utils/link/slugify';
import {Parsys, ParsysType} from '../../structure/Parsys';
import {HeadingElement} from '../elements/HeadingElement';
import {sectionPathParsysIdentifier} from '../sections/GlossarySection';

const HEADING_ELEMENT = <HeadingElement path="heading" order="H3" style="H4" />;

const CONTAINER_PADDING_2_6 = getContainerPadding([2, 6]);

const StyledDd = styled.dd`
    margin: 0;
`;

/**
 * S121 Glossary Item
 */
class InternalGlossaryItem extends React.PureComponent<GlossaryItemModel> {
    public render(): JSX.Element {
        const {orderingTitle, cqPath} = this.props;

        const anchorTargetId = orderingTitle ? slugify(orderingTitle) : '';

        const isGlossarySectionPage =
            cqPath.indexOf(sectionPathParsysIdentifier) !== -1;

        const body = (
            <Container
                padding={{
                    left: CONTAINER_PADDING_2_6,
                    right: CONTAINER_PADDING_2_6
                }}
                wrap={ContainerWrap.always}
            >
                <AuthoringWrapper
                    anchorId={anchorTargetId}
                    title="I121 Glossary Item"
                    bgColor={AuthoringWrapper.BG_COLOR_ITEM}
                >
                    <AnchorTarget anchorId={anchorTargetId}>
                        <Container
                            gutter={ContainerGutter.dynamic0100}
                            wrap={ContainerWrap.always}
                        >
                            <dt>{HEADING_ELEMENT}</dt>
                            <StyledDd>
                                <Parsys
                                    path="glossaryItemParsys"
                                    parsysType={ParsysType.ITEM_PARSYS}
                                />
                            </StyledDd>
                        </Container>
                    </AnchorTarget>
                </AuthoringWrapper>
            </Container>
        );

        return C.isInEditor() || !isGlossarySectionPage ? (
            <Layout>
                <Container
                    padding={{top: ContainerPadding.static300}}
                    wrap={ContainerWrap.always}
                >
                    {body}
                </Container>
            </Layout>
        ) : (
            body
        );
    }
}

export const GlossaryItem = MapTo(
    'vwa-ngw18/components/editorial/items/glossaryItem',
    InternalGlossaryItem
);
