import * as React from 'react';
import styled from 'styled-components';

import {
    Breakpoints,
    Container,
    ContainerDirectionalConfig,
    ContainerGutter,
    ContainerPadding,
    ContainerWrap,
    Layout,
    LayoutConfigurations,
    TextAlignment
} from '@volkswagen-onehub/components-core';
import {TableSectionModel} from '../../../../generated/core';
import {AuthoringWrapper} from '../../../components/AuthoringWrapper';
import {SectionDisclaimerContainer} from '../../../components/disclaimers/SectionDisclaimerContainer';
import {MapTo} from '../../../infrastructure/compatibility/MapTo';
import {C} from '../../../registries/compatibilty';
import {CyAttributeAppender} from '../../../test/CyAttributeAppender';
import {TableParsys} from '../../structure/TableParsys';
import {HeadingElement} from '../elements/HeadingElement';
import {SectionWrapper} from './SectionWrapper';
import {DesktopTable} from './table/DesktopTable';
import {MobileTable} from './table/MobileTable';
import {getNumberOfColumns, getRows, updateTableInAuthor} from './table/utils';
import {getTableWarnings} from '../../../utils/getTableWarnings';
import {GeneralDisclaimerProvider} from '../../../context/disclaimer/GeneralDisclaimerProvider';

const RESOURCE_TYPE = 'vwa-ngw18/components/editorial/sections/tableSection';

const TABLE_PADDING: {
    [key: string]: ContainerDirectionalConfig<ContainerPadding>;
} = {
    2: {left: ContainerPadding.grid005, right: ContainerPadding.grid005},
    3: {left: ContainerPadding.grid003, right: ContainerPadding.grid003},
    4: {left: ContainerPadding.grid001, right: ContainerPadding.grid001},
    5: {left: ContainerPadding.grid001, right: ContainerPadding.grid001},
    6: {left: ContainerPadding.grid001, right: ContainerPadding.grid001}
};

const StyledHeadlineContainer = styled.div`
    padding-left: ${props => props.theme.size.grid002};
    padding-right: ${props => props.theme.size.grid002};

    @media (min-width: ${Breakpoints.b560}px) {
        padding-left: ${props => props.theme.size.grid005};
        padding-right: ${props => props.theme.size.grid006};
    }
`;

const MIN_COLUMNS = 2;

class TableSectionComponentClass extends React.PureComponent<
    TableSectionModel
> {
    public render(): React.ReactNode {
        const {
            anchorId,
            contentId: sectionId,
            contentLabels,
            contentName,
            validationMessages,
            mboxId
        } = this.props;
        const rows = getRows(this.props);
        const columnsCount = getNumberOfColumns(rows);
        // It is not supported to render less then 2 columns
        if (!C.isInEditor() && columnsCount < MIN_COLUMNS) {
            return null;
        }
        const warnings = getTableWarnings(
            validationMessages,
            columnsCount,
            MIN_COLUMNS
        );

        return (
            <AuthoringWrapper
                anchorId={anchorId}
                title="S124 Table Section"
                bgColor={AuthoringWrapper.BG_COLOR_SECTION}
            >
                <SectionWrapper
                    anchorId={anchorId}
                    sectionId={sectionId}
                    contentLabels={contentLabels}
                    contentName={contentName}
                    mboxId={mboxId}
                >
                    <GeneralDisclaimerProvider
                        displayTypes={['T3_SECTION_BASED']}
                    >
                        <Layout
                            appearance={LayoutConfigurations.full}
                            allowOverflowingContent
                        >
                            <CyAttributeAppender name="tableSection">
                                <Container
                                    gutter={ContainerGutter.dynamic0150}
                                    wrap={ContainerWrap.always}
                                >
                                    {C.isInEditor() && warnings}
                                    {this.hasHeadline() && (
                                        <StyledHeadlineContainer>
                                            <HeadingElement
                                                path="headline"
                                                order="H2"
                                                textAlign={TextAlignment.center}
                                            />
                                        </StyledHeadlineContainer>
                                    )}
                                    <Container
                                        gutter={ContainerGutter.dynamic0150}
                                        wrap={ContainerWrap.always}
                                        padding={
                                            !C.isInEditor()
                                                ? TABLE_PADDING[columnsCount]
                                                : undefined
                                        }
                                    >
                                        {C.isInEditor() ? (
                                            <TableParsys
                                                path="tableParsys"
                                                columnsCount={columnsCount}
                                                updateTableInAuthorCallback={
                                                    this
                                                        .updateTableInAuthorCallback
                                                }
                                            />
                                        ) : (
                                            <>
                                                <DesktopTable {...this.props} />
                                                <MobileTable {...this.props} />
                                            </>
                                        )}
                                        <SectionDisclaimerContainer
                                            displayTypes={['T3_SECTION_BASED']}
                                        />
                                    </Container>
                                </Container>
                            </CyAttributeAppender>
                        </Layout>
                    </GeneralDisclaimerProvider>
                </SectionWrapper>
            </AuthoringWrapper>
        );
    }

    private hasHeadline(): boolean {
        return (
            !!this.props.cqItems &&
            !!this.props.cqItems.headline &&
            (C.isInEditor() || !this.props.cqItems.headline.empty)
        );
    }

    private updateTableInAuthorCallback = (): void => {
        updateTableInAuthor(this.props.cqPath);
    };
}

export const TableSection = MapTo(RESOURCE_TYPE, TableSectionComponentClass);
