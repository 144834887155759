import styled from 'styled-components';

import * as React from 'react';
import {ProContraSectionModel} from '../../../../generated/core';
import {AlertBox} from '../../../components/AlertBox';
import {AuthoringWrapper} from '../../../components/AuthoringWrapper';
import {
    ContainerExporter,
    ContainerItem
} from '../../../infrastructure/ContainerExporter';
import {MapTo} from '../../../infrastructure/compatibility/MapTo';
import {C} from '../../../registries/compatibilty';
import {getChildItems} from '../../../utils/container/getContainerChildItems';
import {getDnDContainer} from '../../../utils/container/getDnDContainer';

import {ProContraItem} from '../items/ProContraItem';
import {SectionWrapper} from './SectionWrapper';
import {GeneralDisclaimerProvider} from '../../../context/disclaimer/GeneralDisclaimerProvider';

const getProConItems = (props: ContainerExporter) => {
    const proConItems = getChildItems(props);
    const length = proConItems.length;

    return proConItems.map((child: ContainerItem, idx: number) => (
        <ProContraItem
            key={child.key}
            path={child.key}
            firstInSection={idx === 0}
            lastInSection={idx + 1 === length}
        />
    ));
};

const StyledProsAndConsSection = styled.div`
    margin-bottom: ${props => props.theme.size.dynamic0350};
`;

const ProConAlertBox: React.FunctionComponent = () => (
    <AlertBox>
        <div>{"It's not recommended to use more than 3 Pro/Con Items"}</div>
    </AlertBox>
);

const ProContraSection: React.FunctionComponent<ProContraSectionModel> = props => {
    const {
        anchorId,
        mboxId,
        contentLabels,
        contentName,
        tagTypeBroken,
        contentId: sectionId
    } = props;
    const {length} = getChildItems(props);

    const section = (
        <StyledProsAndConsSection>
            {C.isInEditor() ? (
                <>
                    {getProConItems(props)}
                    {length >= 3 ? <ProConAlertBox /> : null}
                    {getDnDContainer(
                        'vwa-ngw18/components/editorial/sections/proContraSection',
                        props
                    )}
                </>
            ) : (
                <>{getProConItems(props)}</>
            )}
        </StyledProsAndConsSection>
    );

    return (
        <AuthoringWrapper
            title="S111 Pro Contra Section"
            bgColor={AuthoringWrapper.BG_COLOR_ITEM}
            anchorId={anchorId}
            tagTypeBroken={tagTypeBroken}
        >
            <SectionWrapper
                mboxId={mboxId}
                anchorId={anchorId}
                sectionId={sectionId}
                contentLabels={contentLabels}
                contentName={contentName}
            >
                <GeneralDisclaimerProvider displayTypes={['T3_SECTION_BASED']}>
                    {section}
                </GeneralDisclaimerProvider>
            </SectionWrapper>
        </AuthoringWrapper>
    );
};

MapTo(
    'vwa-ngw18/components/editorial/sections/proContraSection',
    ProContraSection
);
