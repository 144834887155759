import * as React from 'react';
import styled from 'styled-components';

import {Layout} from '@volkswagen-onehub/components-core';
import {
    BreakpointHeightConfig,
    IframeIntegratorSectionModel
} from '../../../../generated/core';
import {AuthoringWrapper} from '../../../components/AuthoringWrapper';
import {MapTo} from '../../../infrastructure/compatibility/MapTo';
import {getBreakpointValue} from '../../../utils/getBreakpointValue';
import {
    ColumnLayoutChildType,
    ColumnLayoutWrapper
} from '../../structure/ColumnLayoutWrapper';
import {SectionWrapper} from './SectionWrapper';
import {C} from '../../../registries/compatibilty';
import {AlertBox} from '../../../components/AlertBox';

const createBreakpointConfigs = (config: BreakpointHeightConfig) =>
    config.breakpoint === 'default'
        ? `height: ${config.height};`
        : `@media (min-width: ${getBreakpointValue(config.breakpoint)}px) {
                height: ${config.height};
            }`;

interface StyledIframeProps {
    readonly heightsConfig?: BreakpointHeightConfig[];
}

const StyledIframe = styled.iframe<StyledIframeProps>`
    width: 100%;
    border: none;
    ${props =>
        props.heightsConfig &&
        props.heightsConfig.map(createBreakpointConfigs).join('\n')}
`;

class IframeIntegratorSection extends React.Component<
    IframeIntegratorSectionModel
> {
    public render(): JSX.Element {
        const {
            iframeSrc,
            iframeHeights,
            anchorId,
            contentId: sectionId,
            contentName,
            contentLabels,
            layout,
            tagTypeBroken,
            validationError,
            validationMessages,
            mboxId
        } = this.props;

        const alertBox = validationError && C.isInEditor() && (
            <AlertBox>
                {validationMessages.map((msg, idx) => (
                    <span key={idx}>{msg}</span>
                ))}
            </AlertBox>
        );

        return (
            <AuthoringWrapper
                anchorId={anchorId}
                title="S126 Iframe Integrator Section"
                bgColor={AuthoringWrapper.BG_COLOR_SECTION}
                tagTypeBroken={tagTypeBroken}
            >
                {alertBox}
                <SectionWrapper
                    mboxId={mboxId}
                    anchorId={anchorId}
                    sectionId={sectionId}
                    contentLabels={contentLabels}
                    contentName={contentName}
                >
                    {!iframeSrc && !C.isInEditor() ? null : (
                        <Layout>
                            <ColumnLayoutWrapper
                                childType={ColumnLayoutChildType.MEDIA}
                                layout={layout}
                            >
                                <StyledIframe
                                    src={iframeSrc}
                                    heightsConfig={iframeHeights}
                                />
                            </ColumnLayoutWrapper>
                        </Layout>
                    )}
                </SectionWrapper>
            </AuthoringWrapper>
        );
    }
}

MapTo(
    'vwa-ngw18/components/editorial/sections/iframeIntegratorSection',
    IframeIntegratorSection
);
